import React from "react";
import { string, func, bool } from "prop-types";
import cn from "classnames";

import styles from "./styles.module.scss";

const PickerButton = ({ text, clicked, setClicked, type }) =>
  clicked === false ? (
    <button
      type="button"
      className={cn(styles.unclickedButton, {
        [styles.selectUnselectButton]: type === "selectUnselectButton",
        [styles.casteButton]: type === "casteButton",
        [styles.casteBottomButton]: type === "casteBottomButton",
      })}
      onClick={() => setClicked()}
    >
      {text}
    </button>
  ) : (
    <button
      type="button"
      className={cn(styles.clickedButton, {
        [styles.selectUnselectButton]: type === "selectUnselectButton",
        [styles.casteButton]: type === "casteButton",
        [styles.casteBottomButton]: type === "casteBottomButton",
      })}
    >
      {text}
    </button>
  );

PickerButton.propTypes = {
  text: string.isRequired,
  clicked: bool.isRequired,
  setClicked: func.isRequired,
  type: string.isRequired,
};

export default PickerButton;
