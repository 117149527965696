import React, { useRef } from "react";
import { string, number, func, shape } from "prop-types";
import { useTranslation } from "react-i18next";

import Header from "shared/components/Header";
import InfoText from "shared/components/InfoText";
import PhotoButton from "./PhotoButton";
import AntCanvas from "./AntCanvas";
import styles from "./styles.module.scss";

const AntViewer = ({
  mode,
  antCaste,
  size,
  colors,
  setAntCounter,
  antCounter,
  setCanvasRef,
  canvasImage,
  setCanvasImage,
  setSize,
  setInitialSize,
}) => {
  const { t } = useTranslation();
  const canvasContainerRef = useRef(null);

  return (
    <div className={styles.viewingContainer}>
      <Header text={t("antCounter")} />
      {canvasImage == null ? (
        <div className={styles.promptContainer}>
          <InfoText type="classic" text={t("addPhoto")} />
          <PhotoButton setCanvasImage={setCanvasImage} />
        </div>
      ) : (
        <div ref={canvasContainerRef} className={styles.canvasContainer}>
          <AntCanvas
            image={canvasImage}
            mode={mode}
            antCaste={antCaste}
            size={size}
            colors={colors}
            setAntCounter={setAntCounter}
            antCounter={antCounter}
            setCanvasRef={setCanvasRef}
            canvasContainerRef={canvasContainerRef}
            setSize={setSize}
            setInitialSize={setInitialSize}
          />
        </div>
      )}
    </div>
  );
};

AntViewer.propTypes = {
  mode: string.isRequired,
  antCaste: string.isRequired,
  colors: shape({
    workers: string,
    queens: string,
    eggs: string,
    larvas: string,
    chrysalis: string,
  }).isRequired,
  size: number.isRequired,
  antCounter: shape({
    workers: number,
    queens: number,
    eggs: number,
    larvas: number,
    chrysalis: number,
  }).isRequired,
  setAntCounter: func.isRequired,
  setCanvasRef: func.isRequired,
  canvasImage: shape({
    name: string,
  }),
  setSize: func.isRequired,
  setCanvasImage: func.isRequired,
  setInitialSize: func.isRequired,
};

AntViewer.defaultProps = {
  canvasImage: null,
};

export default AntViewer;
