import React from "react";
import ReactDOM from "react-dom";
import MainPage from "./features/MainPage";

import "./i18n";

import "vendor/plugins.min.css";
import "vendor/preload.min.css";
import "vendor/style.light-green-500.min.css";

ReactDOM.render(
  <React.StrictMode>
    <MainPage />
  </React.StrictMode>,
  document.getElementById("root")
);
