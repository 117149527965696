import React from "react";

import logo from "assets/images/formicopedia8_logo_poziom.png";

const FormicopediaSideBar = () => (
  <div
    className="ms-slidebar sb-slidebar sb-left sb-style-overlay"
    id="ms-slidebar"
  >
    <div className="sb-slidebar-container">
      <header className="ms-slidebar-header">
        <div className="ms-slidebar-title">
          <div className="ms-slidebar-t">
            <span className="ms-logo">
              <img src={logo} alt="" style={{ width: "220px" }} />
            </span>
            <h3>
              FORMICO <span>PEDIA</span>
            </h3>
          </div>
        </div>
      </header>
      <ul
        className="ms-slidebar-menu"
        id="slidebar-menu"
        role="tablist"
        aria-multiselectable="true"
      >
        <li>
          <a className="link" href="https://formicopedia.org/">
            <i className="zmdi zmdi-view-compact" /> Strona główna
          </a>
        </li>
        <li className="panel" role="tab" id="sch1">
          <a
            className="collapsed"
            role="button"
            data-toggle="collapse"
            data-parent="#slidebar-menu"
            href="#"
            aria-expanded="false"
            aria-controls="sc1"
          >
            <i className="zmdi zmdi-home" /> Forum{" "}
          </a>
          <ul
            id="sc1"
            className="panel-collapse collapse"
            role="tabpanel"
            aria-labelledby="sch1"
          >
            <li>
              <a href="https://forum.formicopedia.org/">Strona główna</a>
            </li>
            <li>
              <a href="https://forum.formicopedia.org/viewforum.php?f=39">
                Mrówki
              </a>
            </li>
            <li>
              <a href="https://forum.formicopedia.org/viewforum.php?f=40">
                Domki dla mrówek
              </a>
            </li>
            <li>
              <a href="https://forum.formicopedia.org/viewforum.php?f=41">
                Porozmawiajmy
              </a>
            </li>
            <li>
              <a href="https://forum.formicopedia.org/viewforum.php?f=42">
                Inne
              </a>
            </li>

            <li>
              <a href="https://forum.formicopedia.org/viewforum.php?f=43">
                Bazar
              </a>
            </li>
            <li>
              <a href="https://forum.formicopedia.org/viewforum.php?f=44">
                Tylko dla zalogowanych
              </a>
            </li>
          </ul>
        </li>
        <li className="panel" role="tab" id="sch2">
          <a
            className="collapsed"
            role="button"
            data-toggle="collapse"
            data-parent="#slidebar-menu"
            href="#"
            aria-expanded="false"
            aria-controls="sc2"
          >
            <i className="zmdi zmdi-desktop-mac" /> Baza mrówczej wiedzy{" "}
          </a>
          <ul
            id="sc2"
            className="panel-collapse collapse"
            role="tabpanel"
            aria-labelledby="sch2"
          >
            <li>
              <a href="https://kb.formicopedia.org/Kategoria:Start">Start</a>
            </li>
            <li>
              <a href="https://kb.formicopedia.org/Kompendium_dla_pocz%C4%85tkuj%C4%85cych">
                Kompendium dla początkujących
              </a>
            </li>
            <li>
              <a href="https://kb.formicopedia.org/Pierwszy_krok">
                Pierwszy krok w hodowli mrówek
              </a>
            </li>
            <li>
              <a href="https://kb.formicopedia.org/Drugi_krok">
                Drugi krok w hodowli mrówek
              </a>
            </li>
            <li>
              <a href="https://kb.formicopedia.org/Kategoria:Pytania">
                Pytania i odpowiedzi
              </a>
            </li>
            <li>
              <a href="https://kb.formicopedia.org/Polskie_gatunki_mr%C3%B3wek">
                Lista polskich mrówek
              </a>
            </li>
            <li>
              <a href="https://kb.formicopedia.org/Klucz_do_oznaczania_mr%C3%B3wek">
                Klucze do oznaczania mrówek
              </a>
            </li>
            <li>
              <a href="https://kb.formicopedia.org/Hibernacja_mr%C3%B3wek">
                Hibernacja w warunkach sztucznych
              </a>
            </li>
            <li>
              <a href="https://kb.formicopedia.org/Kategoria:Fotografowanie">
                Fotografowanie
              </a>
            </li>
            <li>
              <a href="https://kb.formicopedia.org/Akcesoria">
                Przydatne akcesoria
              </a>
            </li>
          </ul>
        </li>
        <li>
          <a className="link" href="https://discord.gg/KFmmQ9G">
            {" "}
            <i className="fa fa-users" /> Discord!
          </a>
        </li>
      </ul>
      <div className="ms-slidebar-social ms-slidebar-block">
        <h4 className="ms-slidebar-block-title">Social media</h4>
        <div className="ms-slidebar-social">
          <a
            href="https://www.facebook.com/formicopedia"
            className="btn-circle btn-circle-raised btn-facebook"
          >
            <i className="zmdi zmdi-facebook" />
            <div className="ripple-container" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCVVKKve0sww_p03-LQor8qA"
            className="btn-circle btn-circle-raised btn-youtube"
          >
            <i className="zmdi zmdi-youtube" />
            <div className="ripple-container" />
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default FormicopediaSideBar;
