import React, { useState } from "react";
import { func } from "prop-types";
import { useTranslation } from "react-i18next";

import PickerButton from "shared/components/PickerButton";
import styles from "./styles.module.scss";

const CastePicker = ({ setAntCaste }) => {
  const [mode, setMode] = useState("none");

  const modeChanged = (props) => {
    setAntCaste(props);
    setMode(props);
  };

  const { t } = useTranslation();

  return (
    <div className={styles.buttonsContainer}>
      <PickerButton
        text={t("workers")}
        clicked={mode === "workers"}
        setClicked={() => modeChanged("workers")}
        type="casteButton"
      />
      <PickerButton
        text={t("queens")}
        clicked={mode === "queens"}
        setClicked={() => modeChanged("queens")}
        type="casteButton"
      />
      <PickerButton
        text={t("eggs")}
        clicked={mode === "eggs"}
        setClicked={() => modeChanged("eggs")}
        type="casteButton"
      />
      <PickerButton
        text={t("larvas")}
        clicked={mode === "larvas"}
        setClicked={() => modeChanged("larvas")}
        type="casteButton"
      />
      <PickerButton
        text={t("chrysalis")}
        clicked={mode === "chrysalis"}
        setClicked={() => modeChanged("chrysalis")}
        type="casteBottomButton"
      />
    </div>
  );
};

CastePicker.propTypes = {
  setAntCaste: func.isRequired,
};

export default CastePicker;
