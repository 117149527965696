import React, { useRef } from "react";
import { func } from "prop-types";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

const PhotoButton = ({ setCanvasImage }) => {
  const { t } = useTranslation();

  const inputFile = useRef(null);

  return (
    <div>
      <input
        type="file"
        ref={inputFile}
        className={styles.invisibleFileInput}
        accept="image/*"
        onChange={(ev) => {
          setCanvasImage(ev.target.files[0]);
        }}
      />
      <button
        type="button"
        className={styles.photoPanelButton}
        onClick={() => inputFile.current.click()}
      >
        {t("pickPhoto")}
      </button>
    </div>
  );
};

PhotoButton.propTypes = {
  setCanvasImage: func.isRequired,
};

export default PhotoButton;
