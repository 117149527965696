import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { string } from "prop-types";
import cn from "classnames";

import polandLogo from "assets/images/poland.svg";
import britainLogo from "assets/images/britain.svg";
import franceLogo from "assets/images/france.svg";
import germanyLogo from "assets/images/germany.svg";
import italyLogo from "assets/images/italy.svg";
import globeLogo from "assets/images/globe.svg";

import InfoText from "shared/components/InfoText";
import styles from "./styles.module.scss";

const Header = ({ text }) => {
  const [language, setLanguage] = useState("pl");
  const [allVisible, setAllVisible] = useState(false);

  const { i18n } = useTranslation();

  const onFlagClick = (nation) => {
    setLanguage(nation);
    i18n.changeLanguage(nation);
    setAllVisible(false);
  };

  const onGlobeClick = () => {
    setAllVisible(!allVisible);
  };

  return (
    <div className={styles.header}>
      <InfoText className={styles.headerText} text={text} type="header" />
      {allVisible && (
        <button
          label="unClickButton"
          className={styles.invisibleButton}
          onClick={() => setAllVisible(false)}
        />
      )}
      <div className={styles.flagSelect}>
        <button
          label="globeButton"
          className={styles.globeButton}
          onClick={() => onGlobeClick()}
        >
          <img alt="globe" src={globeLogo} className={styles.globeLogo} />
        </button>
        <button
          label="polandButton"
          className={cn(styles.flagButton, {
            [styles.polandAnimated]: allVisible,
          })}
          style={{ backgroundImage: `url(${polandLogo})` }}
          onClick={() => onFlagClick("pl")}
        />
        <button
          label="britainButton"
          className={cn(styles.flagButton, {
            [styles.britainAnimated]: allVisible,
          })}
          style={{ backgroundImage: `url(${britainLogo})` }}
          onClick={() => onFlagClick("en")}
        />
        <button
          label="franceButton"
          className={cn(styles.flagButton, {
            [styles.franceAnimated]: allVisible,
          })}
          style={{ backgroundImage: `url(${franceLogo})` }}
          onClick={() => onFlagClick("fr")}
        />
        <button
          label="italyButton"
          className={cn(styles.flagButton, {
            [styles.italyAnimated]: allVisible,
          })}
          style={{ backgroundImage: `url(${italyLogo})` }}
          onClick={() => onFlagClick("it")}
        />
        <button
          label="germanyButton"
          className={cn(styles.flagButton, {
            [styles.germanyAnimated]: allVisible,
          })}
          style={{ backgroundImage: `url(${germanyLogo})` }}
          onClick={() => onFlagClick("de")}
        />
      </div>
    </div>
  );
};

Header.propTypes = {
  text: string.isRequired,
};

export default Header;
