import React, { useState } from "react";
import { func } from "prop-types";
import { useTranslation } from "react-i18next";

import PickerButton from "shared/components/PickerButton";
import styles from "./styles.module.scss";

const SelectPicker = ({ setWorkingMode }) => {
  const [mode, setMode] = useState("none");

  const modeChanged = (props) => {
    setWorkingMode(props);
    setMode(props);
  };

  const { t } = useTranslation();

  return (
    <div className={styles.buttonsContainer}>
      <PickerButton
        text={t("select")}
        clicked={mode === "select"}
        setClicked={() => modeChanged("select")}
        type="selectUnselectButton"
      />
      <PickerButton
        text={t("unselect")}
        clicked={mode === "unselect"}
        setClicked={() => modeChanged("unselect")}
        type="selectUnselectButton"
      />
    </div>
  );
};

SelectPicker.propTypes = {
  setWorkingMode: func.isRequired,
};

export default SelectPicker;
