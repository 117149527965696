import React from "react";
import cn from "classnames";
import { number, string, func, shape, instanceOf, bool } from "prop-types";

import SelectPicker from "features/SidePanel/SelectPicker";
import CastePicker from "features/SidePanel/CastePicker";
import AntCounter from "features/SidePanel/AntCounter";
import SizePicker from "features/SidePanel/SizePicker";
import ExtractPanel from "features/SidePanel/ExtractPanel";
import PhotoPanel from "features/SidePanel/PhotoPanel";
import styles from "./styles.module.scss";

const SidePanel = ({
  setWorkingMode,
  setAntCaste,
  antCounter,
  setColors,
  colors,
  size,
  setSize,
  canvasRef,
  sidePanelVisibility,
  setVisibility,
  sidePanelHidden,
  sidePanelActivated,
  setHidden,
  setCanvasImage,
  initialSize,
}) => {
  const handleClick = () => {
    setVisibility(false);
    setHidden(true);
  };

  return (
    <div
      className={cn(styles.mainContainer, {
        [styles.mobileHide]: sidePanelHidden,
        [styles.mobile]: sidePanelActivated,
        [styles.mobileArrive]: sidePanelVisibility,
      })}
    >
      <div className={styles.mobileSidePanelContainer}>
        <button
          type="button"
          label="arrowButton"
          className={styles.mobileSidePanelButton}
          onTouchEnd={() => handleClick()}
        />
      </div>
      <SelectPicker setWorkingMode={setWorkingMode} />
      <CastePicker setAntCaste={setAntCaste} />
      <PhotoPanel setCanvasImage={setCanvasImage} />
      <AntCounter
        antCounter={antCounter}
        setColors={setColors}
        colors={colors}
      />
      <SizePicker size={size} setSize={setSize} initialSize={initialSize} />
      <ExtractPanel
        canvasRef={canvasRef}
        colors={colors}
        antCounter={antCounter}
      />
    </div>
  );
};

SidePanel.propTypes = {
  sidePanelVisibility: bool.isRequired,
  sidePanelHidden: bool.isRequired,
  sidePanelActivated: bool.isRequired,
  setVisibility: func.isRequired,
  setHidden: func.isRequired,
  setWorkingMode: func.isRequired,
  setAntCaste: func.isRequired,
  setColors: func.isRequired,
  setSize: func.isRequired,
  size: number.isRequired,
  canvasRef: shape({
    current: instanceOf(Element),
  }),
  colors: shape({
    workers: string,
    queens: string,
    eggs: string,
    larvas: string,
    chrysalis: string,
  }).isRequired,
  antCounter: shape({
    workers: number,
    queens: number,
    eggs: number,
    larvas: number,
    chrysalis: number,
  }).isRequired,
  setCanvasImage: func.isRequired,
  initialSize: number.isRequired,
};

SidePanel.defaultProps = {
  canvasRef: null,
};

export default SidePanel;
