import React from "react";
import { string, number, func, shape } from "prop-types";

import InfoText from "shared/components/InfoText";
import ColorPicker from "./ColorPicker";
import styles from "./styles.module.scss";

const CasteRow = ({ text, propNumber, caste, setColors, colors }) => (
  <div className={styles.rowContainer}>
    <ColorPicker caste={caste} setColors={setColors} colors={colors} />
    <InfoText text={text} type="small" />
    <InfoText text={propNumber.toString()} type="small" />
  </div>
);

CasteRow.propTypes = {
  text: string.isRequired,
  propNumber: number.isRequired,
  caste: string.isRequired,
  setColors: func.isRequired,
  colors: shape({
    workers: string,
    queens: string,
    eggs: string,
    larvas: string,
    chrysalis: string,
  }).isRequired,
};

export default CasteRow;
