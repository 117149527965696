import React, { useRef } from "react";
import { shape, instanceOf, string, number } from "prop-types";
import { useTranslation } from "react-i18next";

import watermark from "assets/images/watermark.png";
import styles from "./styles.module.scss";

const ExtractPanel = ({ canvasRef, colors, antCounter }) => {
  const { t } = useTranslation();
  const linkRef = useRef(null);

  const manageExtract = () => {
    if (canvasRef != null) {
      let name = "formicopedia-org_";
      const date = new Date();
      name += `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}_${date.getHours()}-${date.getMinutes()}.png`;
      const canvas = canvasRef.current.cloneNode();

      const heightForText = canvas.width / 6;
      canvas.height += canvas.width / 12;
      canvas.height += canvas.width / (3 * 3.4);
      const widthForText = canvas.width / 6;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(canvasRef.current, 0, canvas.width / (3 * 3.4));
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, canvas.width, canvas.width / (3 * 3.4));
      const img = new Image();
      img.src = watermark;
      img.onload = () => {
        ctx.drawImage(
          img,
          canvas.width / 3,
          0,
          canvas.width / 3,
          canvas.width / (3 * 3.4)
        );

        ctx.fillStyle = "black";
        ctx.strokeStyle = "white";
        ctx.globalAlpha = 1;
        ctx.fillRect(
          0,
          canvas.height - heightForText / 2,
          canvas.width,
          heightForText
        );
        ctx.fillStyle = colors.workers;
        ctx.font = `${heightForText / 6}px Roboto`;
        ctx.fillText(
          t("workers"),
          (canvas.width - 5 * heightForText) / 2,
          canvas.height - (heightForText / 2 - heightForText / 5)
        );
        ctx.strokeText(
          t("workers"),
          (canvas.width - 5 * heightForText) / 2,
          canvas.height - (heightForText / 2 - heightForText / 5)
        );
        ctx.fillStyle = "white";
        ctx.fillText(
          antCounter.workers,
          (canvas.width - 5 * heightForText) / 2,
          canvas.height - (heightForText / 2 - (2 * heightForText) / 5)
        );
        ctx.fillStyle = colors.queens;
        ctx.fillText(
          t("queens"),
          heightForText + (canvas.width - 5 * heightForText) / 2,
          canvas.height - (heightForText / 2 - heightForText / 5)
        );
        ctx.strokeText(
          t("queens"),
          heightForText + (canvas.width - 5 * heightForText) / 2,
          canvas.height - (heightForText / 2 - heightForText / 5)
        );
        ctx.fillStyle = "white";
        ctx.fillText(
          antCounter.queens,
          heightForText + (canvas.width - 5 * heightForText) / 2,
          canvas.height - (heightForText / 2 - (2 * heightForText) / 5)
        );
        ctx.fillStyle = colors.eggs;
        ctx.fillText(
          t("eggs"),
          2 * heightForText + (canvas.width - 5 * heightForText) / 2,
          canvas.height - (heightForText / 2 - heightForText / 5)
        );
        ctx.strokeText(
          t("eggs"),
          2 * heightForText + (canvas.width - 5 * heightForText) / 2,
          canvas.height - (heightForText / 2 - heightForText / 5)
        );
        ctx.fillStyle = "white";
        ctx.fillText(
          antCounter.eggs,
          2 * heightForText + (canvas.width - 5 * heightForText) / 2,
          canvas.height - (heightForText / 2 - (2 * heightForText) / 5)
        );
        ctx.fillStyle = colors.larvas;
        ctx.fillText(
          t("larvas"),
          3 * heightForText + (canvas.width - 5 * heightForText) / 2,
          canvas.height - (heightForText / 2 - heightForText / 5)
        );
        ctx.strokeText(
          t("larvas"),
          3 * heightForText + (canvas.width - 5 * heightForText) / 2,
          canvas.height - (heightForText / 2 - heightForText / 5)
        );
        ctx.fillStyle = "white";
        ctx.fillText(
          antCounter.larvas,
          3 * heightForText + (canvas.width - 5 * heightForText) / 2,
          canvas.height - (heightForText / 2 - (2 * heightForText) / 5)
        );
        ctx.fillStyle = colors.chrysalis;
        ctx.fillText(
          t("chrysalis"),
          4 * heightForText + (canvas.width - 5 * heightForText) / 2,
          canvas.height - (heightForText / 2 - heightForText / 5)
        );
        ctx.strokeText(
          t("chrysalis"),
          4 * heightForText + (canvas.width - 5 * heightForText) / 2,
          canvas.height - (heightForText / 2 - heightForText / 5)
        );
        ctx.fillStyle = "white";
        ctx.fillText(
          antCounter.chrysalis,
          4 * heightForText + (canvas.width - 5 * heightForText) / 2,
          canvas.height - (heightForText / 2 - (2 * heightForText) / 5)
        );
        linkRef.current.download = name;
        linkRef.current.href = canvas.toDataURL();
        linkRef.current.click();
      };
    }
  };

  return (
    <>
      <button
        type="button"
        className={styles.extractButton}
        onClick={() => manageExtract()}
      >
        <div className={styles.exportText}> {t("export")} </div>
      </button>
      <a
        ref={linkRef}
        className={styles.extractLink}
        href="#0"
        download="formicopedia-org.png"
      >
        PLACEHOLDER
      </a>
    </>
  );
};

ExtractPanel.propTypes = {
  canvasRef: shape({
    current: instanceOf(Element),
  }),
  colors: shape({
    workers: string,
    queens: string,
    eggs: string,
    larvas: string,
    chrysalis: string,
  }).isRequired,
  antCounter: shape({
    workers: number,
    queens: number,
    eggs: number,
    larvas: number,
    chrysalis: number,
  }).isRequired,
};

ExtractPanel.defaultProps = {
  canvasRef: null,
};

export default ExtractPanel;
