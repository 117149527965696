import React from "react";
import { string } from "prop-types";
import cn from "classnames";

import styles from "./styles.module.scss";

const InfoText = ({ text, type, className }) => (
  <div
    className={cn(
      className,
      {
        [styles.classic]: type === "classic",
        [styles.headerText]: type === "header",
        [styles.small]: type === "small",
      },
      className
    )}
  >
    {text}
  </div>
);

InfoText.propTypes = {
  text: string.isRequired,
  type: string,
};

InfoText.defaultProps = {
  type: "classic",
};

export default InfoText;
