import React from "react";

import logo from "assets/images/formicopedia8_logo_poziom.png";

import styles from "./styles.module.scss";

const FormicopediaHeader = () => (
  <div>
    <header className="ms-header ms-header-white">
      <div className="container container-full">
        <div className="ms-title">
          <a href="https://formicopedia.org">
            <span className="ms-logo">
              <img src={logo} alt="" style={{ width: "220px" }} />
            </span>
            <h1 className="animated fadeInRight animation-delay-6">
              FORMICO
              <span>PEDIA</span>
            </h1>
          </a>
        </div>
        <div className="header-right">
          <a
            href="https://formicopedia.org/login"
            className="btn-circle btn-circle-primary no-focus animated zoomInDown animation-delay-8"
          >
            <i className="zmdi zmdi-account-circle" />
          </a>
          <a
            href="#"
            className="btn-ms-menu btn-circle btn-circle-primary ms-toggle-left animated zoomInDown animation-delay-10"
          >
            <i className="zmdi zmdi-menu" />
          </a>
        </div>
      </div>
    </header>

    <nav className="navbar navbar-static-top yamm ms-navbar ms-navbar-dark">
      <div className="container container-full">
        <div id="navbar" className="navbar-collapse collapse">
          <ul className="nav navbar-nav">
            <li className="dropdown">
              <a href="https://formicopedia.org/" className="">
                Home
              </a>
            </li>
            <li className="dropdown">
              <a
                href="#"
                className="dropdown-toggle"
                data-toggle="dropdown"
                data-hover="dropdown"
                data-name="page"
              >
                Forum
                <i className="zmdi zmdi-chevron-down" />
              </a>
              <ul className="dropdown-menu dropdown-menu-left">
                <li className="dropdown-submenu">
                  <a
                    href="https://forum.formicopedia.org/viewforum.php?f=39"
                    className="has_children"
                  >
                    Mrówki
                  </a>
                  <ul className="dropdown-menu dropdown-menu-left">
                    <li>
                      <a href="https://forum.formicopedia.org/viewforum.php?f=33">
                        Pierwsze kroki
                      </a>
                    </li>
                    <li>
                      <a href="https://forum.formicopedia.org/viewforum.php?f=37">
                        Karmienie mrówek
                      </a>
                    </li>
                    <li>
                      <a href="https://forum.formicopedia.org/viewforum.php?f=1">
                        Mrówki krajowe{" "}
                      </a>
                    </li>
                    <li>
                      <a href="https://forum.formicopedia.org/viewforum.php?f=3">
                        Mrówki egzotyczne{" "}
                      </a>
                    </li>

                    <li>
                      <a href="https://forum.formicopedia.org/viewforum.php?f=26">
                        Rójka czyli czas łowów{" "}
                      </a>
                    </li>
                    <li>
                      <a href="https://forum.formicopedia.org/viewforum.php?f=18">
                        Dział detektywistyczny{" "}
                      </a>
                    </li>
                    <li>
                      <a href="https://forum.formicopedia.org/viewforum.php?f=38">
                        Opowiem wam o moich mrówkach...
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="dropdown-submenu">
                  <a
                    href="https://forum.formicopedia.org/viewforum.php?f=40"
                    className="has_children"
                  >
                    Domki dla mrówek
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a href="https://forum.formicopedia.org/viewforum.php?f=4">
                        Mrowisko domowe
                      </a>
                    </li>
                    <li>
                      <a href="https://forum.formicopedia.org/viewforum.php?f=6">
                        Mrowisko poza domem
                      </a>
                    </li>
                    <li>
                      <a href="https://forum.formicopedia.org/viewforum.php?f=29">
                        Kącik wynalazców i konstruktorów
                      </a>
                    </li>
                    <li>
                      <a href="https://forum.formicopedia.org/viewforum.php?f=31">
                        Makrofotografia
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="dropdown-submenu">
                  <a
                    href="https://forum.formicopedia.org/viewforum.php?f=41"
                    className="has_children"
                  >
                    Porozmawiajmy
                  </a>
                  <ul className="dropdown-menu dropdown-menu-left">
                    <li>
                      <a href="https://forum.formicopedia.org/viewforum.php?f=17">
                        Przedstawmy się
                      </a>
                    </li>
                    <li>
                      <a href="https://forum.formicopedia.org/viewforum.php?f=8">
                        Na każdy temat
                      </a>
                    </li>
                    <li>
                      <a href="https://forum.formicopedia.org/viewforum.php?f=7">
                        Obserwacje przyrodnicze
                      </a>
                    </li>
                    <li>
                      <a href="https://forum.formicopedia.org/viewforum.php?f=16">
                        Relacje z wycieczek
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="dropdown-submenu">
                  <a
                    href="https://forum.formicopedia.org/viewforum.php?f=42"
                    className="has_children"
                  >
                    Inne
                  </a>
                  <ul className="dropdown-menu dropdown-menu-left">
                    <li>
                      <a href="https://forum.formicopedia.org/viewforum.php?f=11">
                        Owady społeczne
                      </a>
                    </li>
                    <li>
                      <a href="https://forum.formicopedia.org/viewforum.php?f=9">
                        Pozostałe hodowle
                      </a>
                    </li>
                    <li>
                      <a href="https://forum.formicopedia.org/viewforum.php?f=14">
                        Media
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="dropdown-submenu">
                  <a
                    href="https://forum.formicopedia.org/viewforum.php?f=43"
                    className="has_children"
                  >
                    Bazar
                  </a>
                  <ul className="dropdown-menu dropdown-menu-left">
                    <li>
                      <a href="https://forum.formicopedia.org/viewforum.php?f=34">
                        Mrówcze targowisko
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="dropdown-submenu">
                  <a
                    href="https://forum.formicopedia.org/viewforum.php?f=44"
                    className="has_children"
                  >
                    Tylko dla zalogowanych
                  </a>
                  <ul className="dropdown-menu dropdown-menu-left">
                    <li>
                      <a href="https://forum.formicopedia.org/viewforum.php?f=30">
                        Dyskusje tylko dla zalogowanych{" "}
                      </a>
                    </li>
                    <li>
                      <a href="https://forum.formicopedia.org/viewforum.php?f=15">
                        Klubowe czyli miejsce na dyskusje dla Modów i Adminów{" "}
                      </a>
                    </li>
                    <li>
                      <a href="https://forum.formicopedia.org/viewforum.php?f=36">
                        Śmietnik
                      </a>
                    </li>
                    <li>
                      <a href="https://forum.formicopedia.org/viewforum.php?f=35">
                        Sprawy organizacyjne
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="divider" />
                <li>
                  <a
                    href="https://forum.formicopedia.org/"
                    className="dropdown-link"
                  >
                    Główna Strona Forum
                  </a>
                </li>
              </ul>
            </li>

            <li className="dropdown yamm-fw">
              <a
                href="https://kb.formicopedia.org/Kategoria:Start"
                className="dropdown-toggle"
                data-toggle="dropdown"
                data-hover="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
                data-name="component"
              >
                Baza mrówczej wiedzy
                <i className="zmdi zmdi-chevron-down" />
              </a>
              <ul className="dropdown-menu dropdown-megamenu animated fadeIn animated-2x">
                <li>
                  <div className="row">
                    <div className="col-sm-3 megamenu-col">
                      <div className="megamenu-block animated fadeInLeft animated-2x">
                        <h3 className="megamenu-block-title">
                          <i className="fa fa-bold" /> Podstawowe
                        </h3>
                        <ul className="megamenu-block-list">
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Kompendium_dla_pocz%C4%85tkuj%C4%85cych"
                            >
                              <i className="fa fa-font" /> Kompendium dla
                              początkujących
                            </a>
                          </li>
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Kategoria:Formikarium"
                            >
                              <i className="fa fa-header" /> Formikaria - w czym
                              trzymać mrówki
                            </a>
                          </li>
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Pierwszy_krok"
                            >
                              <i className="fa fa-arrows-h" /> Pierwszy krok w
                              hodowli mrówek
                            </a>
                          </li>
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Drugi_krok"
                            >
                              <i className="fa fa-quote-right" /> Drugi krok w
                              hodowli mrówek
                            </a>
                          </li>
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Kategoria:S%C5%82ownik"
                            >
                              <i className="fa fa-quote-right" /> Słownik pojęć
                            </a>
                          </li>
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Kategoria:Zakupy"
                            >
                              <i className="fa fa-quote-right" /> Gdzie kupić
                              mrówki?
                            </a>
                          </li>
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Kategoria:Pytania"
                            >
                              <i className="fa fa-quote-right" /> Pytania i
                              odpowiedzi
                            </a>
                          </li>
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Ciekawe_pytania_bez_odpowiedzi"
                            >
                              <i className="fa fa-quote-right" /> masz jakieś
                              pytania?
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-sm-3 megamenu-col">
                      <div className="megamenu-block animated fadeInLeft animated-2x">
                        <h3 className="megamenu-block-title">
                          <i className="fa fa-list-alt" /> Mrówki, mróweczki
                        </h3>
                        <ul className="megamenu-block-list">
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Polskie_gatunki_mr%C3%B3wek"
                            >
                              <i className="zmdi zmdi-view-agenda" /> Lista
                              polskich mrówek
                            </a>
                          </li>
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Kategoria:Mr%C3%B3wki_na_%C5%9Bwiecie"
                            >
                              <i className="zmdi zmdi-view-agenda" /> Mrówki na
                              świecie
                            </a>
                          </li>
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Kategoria:Mr%C3%B3wki"
                            >
                              <i className="zmdi zmdi-info" /> Katalog opisanych
                              przez nas mrówek
                            </a>
                          </li>
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Kategoria:Curtus"
                            >
                              <i className="zmdi zmdi-tag" /> Opisy Mrówek -
                              Curtus
                            </a>
                          </li>
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Klucz_do_oznaczania_mr%C3%B3wek"
                            >
                              <i className="zmdi zmdi-view-list" /> Klucze do
                              oznaczania mrówek
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div className="megamenu-block animated fadeInLeft animated-2x">
                        <h3 className="megamenu-block-title">
                          <i className="fa fa-hand-o-up" /> Hodowla
                        </h3>
                        <ul className="megamenu-block-list">
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Kategoria:Hodowcy"
                            >
                              <i className="fa fa-arrow-circle-right" /> Spis
                              hodowców mrówek
                            </a>
                          </li>
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Antscity"
                            >
                              <i className="fa fa-arrow-circle-right" /> mapa
                            </a>
                          </li>
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Kategoria:Hodowla"
                            >
                              <i className="fa fa-arrow-circle-right" /> Hodowla
                              i doświadczenia hodowlane
                            </a>
                          </li>
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Kategoria:Blog"
                            >
                              <i className="fa fa-arrow-circle-right" /> Mrówcze
                              dzienniki - blogi
                            </a>
                          </li>
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Kategoria:Pokarm"
                            >
                              <i className="fa fa-arrow-circle-right" /> Czym
                              karmić mrówki hodowlane
                            </a>
                          </li>
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Hodowle_karmowe"
                            >
                              <i className="fa fa-arrow-circle-right" /> Hodowle
                              karmowe (mięsko dla mrówek)
                            </a>
                          </li>
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Hibernacja_mr%C3%B3wek"
                            >
                              <i className="fa fa-arrow-circle-right" />{" "}
                              Hibernacja w warunkach sztucznych
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-sm-3 megamenu-col">
                      <div className="megamenu-block animated fadeInRight animated-2x">
                        <h3 className="megamenu-block-title">
                          <i className="zmdi zmdi-tab" /> Literatura
                        </h3>
                        <ul className="megamenu-block-list">
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Kategoria:Literatura"
                            >
                              <i className="zmdi zmdi-view-day" /> literatura
                            </a>
                          </li>
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Literatura_ksi%C4%85%C5%BCkowa"
                            >
                              <i className="zmdi zmdi-tab" /> literatura
                              książkowa{" "}
                            </a>
                          </li>
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Artyku%C5%82y_internetowe"
                            >
                              <i className="zmdi zmdi-menu" /> artykuły w
                              internecie
                            </a>
                          </li>
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Strony_o_mr%C3%B3wkach"
                            >
                              <i className="zmdi zmdi-menu" /> Linki do stron o
                              mrówkach
                            </a>
                          </li>
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Taksonomia"
                            >
                              <i className="zmdi zmdi-menu" /> Taksonomia
                            </a>
                          </li>
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Systematyka"
                            >
                              <i className="zmdi zmdi-menu" /> systematyka
                            </a>
                          </li>
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Kategoria:S%C5%82ownik"
                            >
                              <i className="zmdi zmdi-menu" /> słownik pojęć
                            </a>
                          </li>
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Kategoria:Myrmekolog"
                            >
                              <i className="zmdi zmdi-menu" /> Znani
                              myrmekolodzy
                            </a>
                          </li>
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/index.php?title=Kategoria:Myrmekologia&action=edit&redlink=1"
                            >
                              <i className="zmdi zmdi-menu" /> nauka
                            </a>
                          </li>
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Kategoria:Opowiadania"
                            >
                              <i className="zmdi zmdi-menu" /> opowiadania
                            </a>
                          </li>
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Kategoria:Bajka"
                            >
                              <i className="zmdi zmdi-menu" /> bajki
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-sm-3 megamenu-col">
                      <div className="megamenu-block animated fadeInRight animated-2x">
                        <h3 className="megamenu-block-title">
                          <i className="fa fa-area-chart" /> Inne
                        </h3>
                        <ul className="megamenu-block-list">
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Kategoria:Towarzyskie"
                            >
                              <i className="fa fa-users" /> Towarzyskie
                              spotkania i zloty mrówkolubów
                            </a>
                          </li>
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Ciekawostki"
                            >
                              <i className="fa fa-question" /> Ciekawostki
                            </a>
                          </li>
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Kategoria:Rozrywka"
                            >
                              <i className="fa fa-line-chart" /> Rozrywka
                            </a>
                          </li>
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Kategoria:Termity"
                            >
                              <i className="fa fa-pie-chart" /> Termity
                            </a>
                          </li>
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Kategoria:Galeria"
                            >
                              <i className="fa fa-image" /> Galeria
                            </a>
                          </li>
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Kategoria:Video"
                            >
                              <i className="fa fa-youtube-play" /> Filmy
                            </a>
                          </li>
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Kategoria:Start"
                            >
                              <i className="zmdi zmdi-view-agenda" /> Strona
                              główna KB
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div className="megamenu-block animated fadeInRight animated-2x">
                        <h3 className="megamenu-block-title">
                          <i className="zmdi zmdi-folder-star-alt" /> Technika
                        </h3>
                        <ul className="megamenu-block-list">
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Kategoria:Technika"
                            >
                              <i className="zmdi zmdi-card-membership" />{" "}
                              Technika - kącik złotej rączki
                            </a>
                          </li>
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Budowa_formikarium"
                            >
                              <i className="zmdi zmdi-card-giftcard" /> Budowa
                              formikarium - jak wygląda i z czego tworzyć
                            </a>
                          </li>
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Kategoria:Fotografowanie"
                            >
                              <i className="zmdi zmdi-n-6-square" />{" "}
                              Fotografowanie
                            </a>
                          </li>
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Jak_wysy%C5%82a%C4%87_mr%C3%B3wki"
                            >
                              <i className="zmdi zmdi-play-circle" /> Jak
                              wysyłać mrówki?
                            </a>
                          </li>
                          <li>
                            <a
                              className="withripple"
                              href="https://kb.formicopedia.org/Akcesoria"
                            >
                              <i className="zmdi zmdi-view-dashboard" />{" "}
                              Przydatne akcesoria
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </li>
            <li className="dropdown">
              <a
                href="#"
                className="dropdown-toggle animated fadeIn animation-delay-1"
                data-toggle="dropdown"
                data-hover="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
                data-name="ecommerce"
              >
                Formicopedia
                <i className="zmdi zmdi-chevron-down" />
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a href="https://formicopedia.org/historia">Historia</a>
                </li>
              </ul>
            </li>
            <li className={"active"}>
              <a href="https://licznik.formicopedia.org/">
                {" "}
                <i className="fa fa-calculator" /> Licznik mrówek
              </a>
            </li>
            <li>
              <a href="https://discord.gg/UEXc6zugDx">
                {" "}
                <i className="fa fa-users" /> Discord!
              </a>
            </li>
          </ul>
        </div>

        <img
          alt="formicopedia_logo"
          src={logo}
          className={styles.formicopediaLogo}
        />

        <a href="#" className="sb-toggle-left btn-navbar-menu">
          <i className="zmdi zmdi-menu" />
        </a>
      </div>
    </nav>
  </div>
);

export default FormicopediaHeader;
