import React from "react";
import { shape, func, number, string } from "prop-types";
import { useTranslation } from "react-i18next";

import CasteRow from "features/SidePanel/AntCounter/CasteRow";
import styles from "./styles.module.scss";

const AntCounter = ({ antCounter, setColors, colors }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.counterContainer}>
      <CasteRow
        text={t("workers")}
        propNumber={antCounter.workers}
        caste="workers"
        setColors={setColors}
        colors={colors}
      />
      <CasteRow
        text={t("queens")}
        propNumber={antCounter.queens}
        caste="queens"
        setColors={setColors}
        colors={colors}
      />
      <CasteRow
        text={t("eggs")}
        propNumber={antCounter.eggs}
        caste="eggs"
        setColors={setColors}
        colors={colors}
      />
      <CasteRow
        text={t("larvas")}
        propNumber={antCounter.larvas}
        caste="larvas"
        setColors={setColors}
        colors={colors}
      />
      <CasteRow
        text={t("chrysalis")}
        propNumber={antCounter.chrysalis}
        caste="chrysalis"
        setColors={setColors}
        colors={colors}
      />
    </div>
  );
};

AntCounter.propTypes = {
  antCounter: shape({
    workers: number,
    queens: number,
    eggs: number,
    larvas: number,
    chrysalis: number,
  }).isRequired,
  colors: shape({
    workers: string,
    queens: string,
    eggs: string,
    larvas: string,
    chrysalis: string,
  }).isRequired,
  setColors: func.isRequired,
};

export default AntCounter;
