import React from "react";
import { number, func } from "prop-types";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

const SizePicker = ({ size, setSize, initialSize }) => {
  const { t } = useTranslation();

  const manageSize = (e) => {
    setSize(parseInt(e.target.value, 10));
    window.localStorage.setItem("size", parseInt(e.target.value, 10));
  };

  return (
    <div className={styles.sizerContainer}>
      <div className={cn(styles.sizeInfo, styles.sizeInfoSmall)}>
        {t("pointSize")}
      </div>
      <input
        type="range"
        value={size}
        className={styles.sizeScroll}
        min={2}
        max={initialSize + 18}
        onChange={(e) => manageSize(e)}
      />
    </div>
  );
};

SizePicker.propTypes = {
  size: number.isRequired,
  setSize: func.isRequired,
  initialSize: number.isRequired,
};

export default SizePicker;
