import React, { useRef } from "react";
import { func } from "prop-types";

import styles from "./styles.module.scss";

const PhotoButton = ({ setCanvasImage }) => {
  const inputFile = useRef(null);

  return (
    <div>
      <input
        type="file"
        ref={inputFile}
        className={styles.invisibleFileInput}
        accept="image/*"
        onChange={(ev) => {
          setCanvasImage(ev.target.files[0]);
        }}
      />
      <button
        type="button"
        className={styles.photoButton}
        onClick={() => inputFile.current.click()}
      >
        +
      </button>
    </div>
  );
};

PhotoButton.propTypes = {
  setCanvasImage: func.isRequired,
};

export default PhotoButton;
