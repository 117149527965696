import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import InfoText from "shared/components/InfoText";
import AntViewer from "features/AntViewer";
import SidePanel from "features/SidePanel";
import FormicopediaHeader from "features/FormicopediaHeader";
import FormicopediaSideBar from "features/FormicopediaSideBar";
import styles from "./styles.module.scss";

const MainPage = () => {
  const { t } = useTranslation();

  const [antCaste, setAntCaste] = useState("none");
  const [mode, setWorkingMode] = useState("none");
  const [sidePanelVisibility, setVisibility] = useState(false);
  const [sidePanelActivated, setActivated] = useState(false);
  const [sidePanelHidden, setHidden] = useState(true);
  const [canvasImage, setCanvasImage] = useState(null);
  const [size, setSize] = useState(7);
  const [initialSize, setInitialSize] = useState(7);
  const [colors, setColors] = useState({
    workers: "#FF0000",
    queens: "#FFB300",
    eggs: "#00FF09",
    larvas: "#FF00E6",
    chrysalis: "#012BFF",
  });
  const [canvasRef, setCanvasRef] = useState();
  const [antCounter, setAntCounter] = useState({
    workers: 0,
    queens: 0,
    eggs: 0,
    larvas: 0,
    chrysalis: 0,
  });

  const handleClick = () => {
    setVisibility(true);
    setActivated(true);
    setHidden(false);
  };

  useEffect(() => {
    if (
      window.localStorage.getItem("colors") !== null &&
      window.localStorage.getItem("colors") !== undefined
    ) {
      setColors(JSON.parse(window.localStorage.getItem("colors")));
    }
    if (
      window.localStorage.getItem("size") !== null &&
      window.localStorage.getItem("size") !== undefined
    ) {
      setSize(JSON.parse(window.localStorage.getItem("size")));
    }
  }, []);

  return (
    <div className={styles.mainContainer}>
      <FormicopediaHeader />
      <FormicopediaSideBar />
      <div className={styles.middleContainer}>
        <AntViewer
          mode={mode}
          antCaste={antCaste}
          size={size}
          colors={colors}
          setAntCounter={setAntCounter}
          antCounter={antCounter}
          setCanvasRef={setCanvasRef}
          canvasImage={canvasImage}
          setCanvasImage={setCanvasImage}
          setSize={setSize}
          setInitialSize={setInitialSize}
        />
        <SidePanel
          setWorkingMode={setWorkingMode}
          setAntCaste={setAntCaste}
          antCounter={antCounter}
          setColors={setColors}
          colors={colors}
          size={size}
          setSize={setSize}
          canvasRef={canvasRef}
          sidePanelVisibility={sidePanelVisibility}
          setVisibility={setVisibility}
          sidePanelHidden={sidePanelHidden}
          sidePanelActivated={sidePanelActivated}
          setHidden={setHidden}
          setCanvasImage={setCanvasImage}
          initialSize={initialSize}
        />
      </div>
      <div className={styles.mobileContainer}>
        <div className={styles.mobileTextContainer}>
          <InfoText text={t("currentCaste")} type="small" />
          &nbsp;
          <InfoText text={t(antCaste)} type="small" />
        </div>
        <button
          type="button"
          label="arrowButton"
          className={styles.mobileSidePanelButton}
          onTouchEnd={() => handleClick()}
        />
      </div>
    </div>
  );
};

export default MainPage;
