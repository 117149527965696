import React, { Component } from "react";
// eslint-disable-next-line import/no-unresolved
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { BrowserView, MobileView } from "react-device-detect";
import { string, number, shape, func, instanceOf } from "prop-types";

import styles from "./styles.module.scss";

class AntCanvas extends Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();

    const { mode, antCaste, colors, size } = props;
    this.state = {
      size,
      mode,
      colors,
      antCaste,
      width: 0,
      height: 0,
      points: [],
      counter: 0,
      currentTouchPosition: {
        x: 0,
        y: 0,
      },
      currentZoomPosition: {
        x: 0,
        y: 0,
      },
      initialSize: 7,
    };

    this.initializeState();

    this.isMoving = false;
    this.DELTA_PER_ZOOM_LEVEL = 400;
    this.MAX_ZOOM_SPEED = 0.1;
    this.MIN_ZOOM = 1.0;
    this.MAX_ZOOM = 10;
    this.PADDING = 16;
  }

  componentDidMount() {
    this.initializeImage();
  }

  componentDidUpdate(prevProps) {
    const { mode, antCaste, colors, size, antCounter, image } = this.props;

    if (
      prevProps.mode !== mode ||
      prevProps.antCaste !== antCaste ||
      prevProps.colors !== colors ||
      prevProps.antCounter !== antCounter ||
      prevProps.size !== size
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(
        {
          mode,
          antCaste,
          colors,
          counter: antCounter,
          size,
        },
        () => {
          this.drawImage();
          window.localStorage.setItem(image.name, JSON.stringify(this.state));
        }
      );
    } else if (prevProps.image !== image) {
      this.initializeState();
      this.initializeImage();
    }
  }

  clamp = (n, min, max) => Math.max(min, Math.min(n, max));

  updateStyles = () => {
    const {
      zoom,
      pan: [x, y],
    } = this.settings;
    this.canvas.style.transform = `scale(${zoom}) translate(${x}px, ${y}px)`;
  };

  onZoom = (event) => {
    event.stopPropagation();
    this.settings.zoom -= this.clamp(
      event.deltaY / this.DELTA_PER_ZOOM_LEVEL,
      -this.MAX_ZOOM_SPEED,
      this.MAX_ZOOM_SPEED
    );
    this.settings.zoom = this.clamp(
      this.settings.zoom,
      this.MIN_ZOOM,
      this.MAX_ZOOM
    );
    this.changePan(0, 0);
    this.updateStyles();
  };

  onPanStart = (event) => {
    event.preventDefault();
    if (event.button === 2) {
      this.settings.isPanning = true;
    }
  };

  onPanEnd = (event) => {
    event.preventDefault();
    this.settings.isPanning = false;
  };

  initializeState = () => {
    const { mode, antCaste, colors, size, setAntCounter } = this.props;

    this.setState({
      size,
      mode,
      colors,
      antCaste,
      width: 0,
      height: 0,
      points: [],
      counter: 0,
      currentTouchPosition: {
        x: 0,
        y: 0,
      },
      currentZoomPosition: {
        x: 0,
        y: 0,
      },
      initialSize: 7,
    });
    setAntCounter({ workers: 0, queens: 0, eggs: 0, larvas: 0, chrysalis: 0 });

    this.settings = {
      zoom: 1.0,
      pan: [0, 0],
      isPanning: false,
      startingTime: 0,
      endingTime: 0,
    };
  };

  initializeImage = () => {
    const { image, setAntCounter, setSize, setInitialSize } = this.props;
    const { initialSize } = this.state;

    this.canvas = this.canvasRef.current;
    this.ctx = this.canvas.getContext("2d");

    if (
      JSON.parse(window.localStorage.getItem(image.name)) !== null &&
      JSON.parse(window.localStorage.getItem(image.name)) !== undefined
    ) {
      if (
        JSON.parse(window.localStorage.getItem(image.name)).initialSize ===
          null ||
        JSON.parse(window.localStorage.getItem(image.name)).initialSize ===
          undefined
      ) {
        setInitialSize(initialSize);
      } else {
        setInitialSize(
          JSON.parse(window.localStorage.getItem(image.name)).initialSize
        );
      }
      setAntCounter(
        JSON.parse(window.localStorage.getItem(image.name)).counter
      );
      this.setState({
        points: JSON.parse(window.localStorage.getItem(image.name)).points,
      });
    } else {
      const img = new Image();
      img.src = URL.createObjectURL(image);
      img.onload = () => {
        this.setState({
          size: Math.round(
            this.clamp(
              Math.max(
                this.clamp((img.height / 800) * 7, 7, 100),
                this.clamp((img.width / 800) * 7, 7, 100)
              ),
              7,
              100
            )
          ),
          initialSize: Math.round(
            this.clamp(
              Math.max(
                this.clamp((img.height / 800) * 7, 7, 100),
                this.clamp((img.width / 800) * 7, 7, 100)
              ),
              7,
              100
            )
          ),
        });
        setInitialSize(
          Math.round(
            this.clamp(
              Math.max(
                this.clamp((img.height / 800) * 7, 7, 100),
                this.clamp((img.width / 800) * 7, 7, 100)
              ),
              7,
              100
            )
          )
        );
        setSize(
          Math.round(
            this.clamp(
              Math.max(
                this.clamp((img.height / 800) * 7, 7, 100),
                this.clamp((img.width / 800) * 7, 7, 100)
              ),
              7,
              100
            )
          )
        );
      };
    }
    this.drawImage();
  };

  changePan = (movementX, movementY) => {
    const { canvasContainerRef } = this.props;

    const {
      zoom,
      pan: [x, y],
    } = this.settings;

    const maxTranslationX = Math.abs(
      (canvasContainerRef.current.getBoundingClientRect().width -
        this.canvas.getBoundingClientRect().width -
        this.PADDING * 2) /
        2 /
        zoom
    );
    const maxTranslationY = Math.abs(
      (canvasContainerRef.current.getBoundingClientRect().height -
        this.canvas.getBoundingClientRect().height -
        this.PADDING * 2) /
        2 /
        zoom
    );

    const newX = this.clamp(
      x + movementX / zoom,
      -maxTranslationX,
      maxTranslationX
    );
    const newY = this.clamp(
      y + movementY / zoom,
      -maxTranslationY,
      maxTranslationY
    );

    this.settings.pan = [newX, newY];
  };

  onPan = (event) => {
    if (this.settings.isPanning) {
      this.changePan(event.movementX, event.movementY);
      this.updateStyles();
    }
  };

  handleClick = (event) => {
    const { mode, setAntCounter, antCounter, antCaste, size } = this.props;
    const { points } = this.state;
    const rect = this.canvas.getBoundingClientRect();
    const scaleX = this.canvas.width / rect.width;
    const scaleY = this.canvas.height / rect.height;

    const newPoint = {
      x: (event.clientX - rect.left) * scaleX,
      y: (event.clientY - rect.top) * scaleY,
      caste: antCaste,
    };

    if (mode === "select" && antCaste !== "none") {
      setAntCounter({
        ...antCounter,
        [antCaste]: antCounter[antCaste] + 1,
      });

      this.setState((state) => ({
        points: [...state.points, newPoint],
      }));
    } else if (mode === "unselect" && antCaste !== "none") {
      for (let i = 0; i < points.length; i += 1) {
        if (
          points[i].caste === newPoint.caste &&
          Math.sqrt(
            (points[i].x - newPoint.x) * (points[i].x - newPoint.x) +
              (points[i].y - newPoint.y) * (points[i].y - newPoint.y)
          ) <
            size + 2
        ) {
          // +2 przez obwodke
          const arr = points;
          arr.splice(i, 1);
          this.setState({
            points: arr,
          });

          setAntCounter({
            ...antCounter,
            [antCaste]: antCounter[antCaste] - 1,
          });
          break;
        }
      }
    }
  };

  handleTouchMove = (event) => {
    if (event.touches.length === 1 && this.isMoving) {
      const { currentTouchPosition } = this.state;

      const deltaX = event.changedTouches[0].clientX - currentTouchPosition.x;
      const deltaY = event.changedTouches[0].clientY - currentTouchPosition.y;

      this.setState({
        currentTouchPosition: {
          x: event.changedTouches[0].clientX,
          y: event.changedTouches[0].clientY,
        },
      });

      this.changePan(deltaX, deltaY);
      this.updateStyles();
    }
  };

  handleTouchStart = (event) => {
    if (event.touches.length === 1) {
      this.isMoving = true;
      this.setState({
        currentTouchPosition: {
          x: event.changedTouches[0].clientX,
          y: event.changedTouches[0].clientY,
        },
      });
    }
  };

  handleTouchEnd = () => {
    this.isMoving = false;
  };

  drawImage = () => {
    const { image } = this.props;

    const img = new Image();
    img.src = URL.createObjectURL(image);
    img.onload = () => {
      this.setState({
        width: img.width,
        height: img.height,
      });
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.ctx.drawImage(img, 0, 0);
      this.drawPoints();

      this.changePan(0, 0);
      this.updateStyles();
    };
  };

  drawPoints = () => {
    const { colors, size, setCanvasRef } = this.props;
    const { points } = this.state;

    for (let i = 0; i < points.length; i += 1) {
      this.ctx.fillStyle = colors[points[i].caste];
      this.ctx.beginPath();
      this.ctx.arc(points[i].x, points[i].y, size, 0, 2 * Math.PI);
      this.ctx.fill();
      this.ctx.fillStyle = "#FFFFFF";
      this.ctx.arc(points[i].x, points[i].y, size + 1, 0, 2 * Math.PI);
      this.ctx.stroke();
    }
    setCanvasRef(this.canvasRef);
  };

  render() {
    const { width, height } = this.state;

    return (
      <>
        <BrowserView>
          <canvas
            className={styles.canvas}
            ref={this.canvasRef}
            width={width}
            height={height}
            onClick={(event) => this.handleClick(event)}
            onMouseDown={this.onPanStart}
            onMouseMove={this.onPan}
            onMouseUp={this.onPanEnd}
            onMouseLeave={this.onPanEnd}
            onWheel={this.onZoom}
            onContextMenu={(e) => e.preventDefault()}
          />
        </BrowserView>
        <MobileView className={styles.mobileViewClass}>
          <TransformWrapper
            panning={{ disabled: true }}
            onZoom={(e) => {
              const { currentZoomPosition } = this.state;

              const deltaY = e.state.positionY - currentZoomPosition.y;

              this.setState({
                currentZoomPosition: {
                  y: e.state.positionY,
                },
              });

              this.settings.zoom -= this.clamp(
                deltaY / this.DELTA_PER_ZOOM_LEVEL,
                -this.MAX_ZOOM_SPEED,
                this.MAX_ZOOM_SPEED
              );
              this.settings.zoom = this.clamp(
                this.settings.zoom,
                this.MIN_ZOOM,
                this.MAX_ZOOM
              );
              this.changePan(0, 0);
              this.updateStyles();
            }}
          >
            <TransformComponent>
              <canvas
                className={styles.canvas}
                ref={this.canvasRef}
                width={width}
                height={height}
                onClick={(event) => this.handleClick(event)}
                onContextMenu={(e) => e.preventDefault()}
                onTouchStart={(e) => this.handleTouchStart(e)}
                onTouchMove={(e) => this.handleTouchMove(e)}
                onTouchEnd={(e) => this.handleTouchEnd(e)}
              />
            </TransformComponent>
          </TransformWrapper>
        </MobileView>
      </>
    );
  }
}

AntCanvas.propTypes = {
  mode: string.isRequired,
  antCaste: string.isRequired,
  colors: shape({
    workers: string,
    queens: string,
    eggs: string,
    larvas: string,
    chrysalis: string,
  }).isRequired,
  size: number.isRequired,
  antCounter: shape({
    workers: number,
    queens: number,
    eggs: number,
    larvas: number,
    chrysalis: number,
  }).isRequired,
  image: shape({
    name: string,
  }).isRequired,
  setCanvasRef: func.isRequired,
  setAntCounter: func.isRequired,
  canvasContainerRef: shape({
    current: instanceOf(Element),
  }).isRequired,
  setSize: func.isRequired,
  setInitialSize: func.isRequired,
};

export default AntCanvas;
