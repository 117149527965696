import React, { useRef } from "react";
import { string, func, shape } from "prop-types";

import styles from "./styles.module.scss";

const ColorPicker = ({ caste, setColors, colors }) => {
  const colorPickerRef = useRef(null);
  const changeColor = (color) => {
    window.localStorage.setItem(
      "colors",
      JSON.stringify({
        ...colors,
        [caste]: color,
      })
    );
    setColors({
      ...colors,
      [caste]: color,
    });
  };

  return (
    <div className={styles.colorPickerContainer}>
      <input
        value={colors[caste]}
        ref={colorPickerRef}
        className={styles.colorPicker}
        type="color"
        onChange={(event) => changeColor(event.target.value)}
      />
      <button
        label="colorPicker"
        type="button"
        className={styles.colorShower}
        style={{ backgroundColor: [colors[caste]] }}
        onClick={() => colorPickerRef.current.click()}
      />
    </div>
  );
};

ColorPicker.propTypes = {
  caste: string.isRequired,
  setColors: func.isRequired,
  colors: shape({
    workers: string,
    queens: string,
    eggs: string,
    larvas: string,
    chrysalis: string,
  }).isRequired,
};

export default ColorPicker;
